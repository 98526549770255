<template>
  <vuestic-widget :loading="loading" class="automation-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Edit Template</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="userCreateForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="templateCreateForm">
        <div class="row">
          <div class="col-sm-12">
            <text-input name="Name" v-model="formData.name" label="Name" validate="required" />
          </div>
          <div class="col-sm-12">
            <text-input name="Order" v-model="formData.order" label="Order" validate="required" />
          </div>
          <div v-if="provider == 'sms'" class="col-md-12 mt-2">
            <validation-provider rules="required" v-slot="{}" name="Message">
              <TextareaEmojiPicker v-model="formData.message" :focused=true :rows="7" :enabledMms="true"
                ref="textareaEmoji" :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false"
                :optText="optText" :images="formData.medias" @done="onSelectImg" />
            </validation-provider>
          </div>
          <div v-else-if="provider == 'email'" class="col-md-12 mt-2">
            <validation-provider rules="required" v-slot="{}" name="Message">
              <TextEditorPicker v-model="formData.message" :loading="loading" :enabledEmoji="true"
                :height="280" :showSendButton="false" ref="texteditor" :files="formData.medias" @done="onSelectImg" />
            </validation-provider>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary btn-danger mr-2" @click="onBack">
              <span>Back <i class="fa fa-arrow-left"></i></span>
            </button>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto;" />
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
import { mapState } from 'vuex';
import TextareaEmojiPicker from '@/components/common/TextareaEmojiPicker'
import TextEditorPicker from "@/components/common/TextEditorPicker";

export default {
  components: {
    TextareaEmojiPicker, TextEditorPicker,
  },
  data() {
    return {
      loading: false,
      users: [],
      provider: '',
      formData: {
        name: '',
        message: '',
        medias: [],
        active: false,
      },
      medias: [],
      addType: undefined,
      isOpenModalAdd: false,
      newAgent: undefined,
    };
  },

  computed: {
    ...mapState('template', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
    }),

    optText() {
      return this.$store.getters['auth/optText']
    },

    maxLength() {
      return 1600 - this.optText.length
    },

    business() {
      return this.$store.state.auth.user.business
    }
  },
  mounted() {
    if (this.$route.params.id) {
      const id = this.$route.params.id
      this.loadData(id);
    } else {
      this.redirectOnError()
    }
  },
  methods: {
    redirectOnError() {
      this.$router.push({ name: 'business.templates.index' });
    },

    onBack() {
      this.$router.push({ name: 'business.templates.index', query: { type: this.provider } })
    },

    onSelectImg(imgs) {
      this.medias = imgs
    },

    loadData(id) {
      this.loading = true;
      this.$store
        .dispatch('template/get', id)
        .then((res) => {
          this.loading = false;
          this.initData(res.data);
        })
        .catch(() => {
          this.loading = false;
          this.redirectOnError()
        })
    },

    initData(data) {
      this.medias = data.medias
      this.formData =
      {
        ...this.formData,
        id: data.id,
        name: data.name,
        message: data.message,
        order: data.order,
        medias: data.medias,
        active: data.active,
        provider: data.provider,
      }
      this.provider = data.provider === 2 ? 'email' : 'sms'
    },

    onFormSubmit() {
      const param = {
        id: this.formData.id,
        data: {
          ...this.formData,
          medias: this.medias,
        }
      }
      this.$store
        .dispatch('template/update', param)
        .then(() => {
        })
        .catch(() => {
        })
    },
  },

};
</script>

<style lang="scss" scoped>
.templateCreateForm {
  max-width: 768px;
}
</style>